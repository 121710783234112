import React, { useContext, useMemo, createContext } from 'react'
import { useQuery } from 'apollo-client'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'

import modifyAbTests, { type ModifiedAbTests } from './modifyAbTests'
import modifyFeatures, { type ModifiedFeatures } from './modifyFeatures'
import abFtQuery from './graph/abFt.graphql'


export type AbFtData = {
  abTests: ModifiedAbTests
  features: ModifiedFeatures
}

const defaultValue = {
  abTests: {},
  features: {},
}

const AbFtContext = createContext<AbFtData>(defaultValue)

export const useAbFt = () => useContext(AbFtContext)

export const AbFtProvider: React.CFC = ({ children }) => {
  const { data } = useQuery(abFtQuery, {
    fetchPolicy: 'cache-first',
  })

  const value = useMemo(() => {
    if (!data) {
      return defaultValue
    }

    return {
      abTests: modifyAbTests(data.abTests?.data?.abTests, cookieStorage.getItem(constants.cookieNames.abTestsOverride)),
      features: modifyFeatures(data.features?.data?.featureToggles, cookieStorage.getItem(constants.cookieNames.featuresOverride)),
    }
  }, [ data ])

  return (
    <AbFtContext.Provider value={value}>
      {children}
    </AbFtContext.Provider>
  )
}
