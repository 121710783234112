import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type ReferralInviteMutationVariables = Types.Exact<{
  input: Types.ReferralInviteInput;
}>;


type ReferralInviteMutationPayload = { __typename?: 'Mutation', referralInvite: { __typename?: 'ReferralInvitePayload', data: Array<{ __typename?: 'ReferralInvitation', success: boolean, email: string }> | null, error: { __typename?: 'ReferralInviteError', message: string, referralInviteErrorCode: Types.ReferralInviteErrorCode } | { __typename?: 'SecurityError', message: string, securityErrorCode: Types.SecurityErrorCode } | null } };


 const ReferralInviteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ReferralInvite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralInviteInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"referralInvite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}},{"kind":"Field","name":{"kind":"Name","value":"error"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SecurityError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"securityErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ReferralInviteError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"referralInviteErrorCode"},"name":{"kind":"Name","value":"errorCode"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}}]}}]} as unknown as DocumentNode<ReferralInviteMutationPayload, ReferralInviteMutationVariables>;


export type ReferralInviteVariables = ReferralInviteMutationVariables;
export type ReferralInvitePayload = ReferralInviteMutationPayload;

// remove fragments duplicates
// @ts-ignore
ReferralInviteDocument['definitions'] = [ ...new Set(ReferralInviteDocument.definitions) ];

export default ReferralInviteDocument;