import type * as Types from '../../../../typings/graphql';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
type ShownPopupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


type ShownPopupsQueryPayload = { __typename?: 'Query', currentUser: { __typename?: 'UserPayload', data: { __typename?: 'UserData', shownPopups: Array<{ __typename?: 'PopupShown', popupId: string, count: number, lastUpdated: string | null }> } | null } };


 const ShownPopupsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"ShownPopups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"data"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"shownPopups"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"PopupShown"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"PopupShown"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"PopupShown"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"popupId"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"lastUpdated"}}]}}]} as unknown as DocumentNode<ShownPopupsQueryPayload, ShownPopupsQueryVariables>;


export type ShownPopupsVariables = ShownPopupsQueryVariables;
export type ShownPopupsPayload = ShownPopupsQueryPayload;

// remove fragments duplicates
// @ts-ignore
ShownPopupsDocument['definitions'] = [ ...new Set(ShownPopupsDocument.definitions) ];

export default ShownPopupsDocument;