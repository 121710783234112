import type { AbFtPayload } from './graph/abFt.graphql'


export type AbTest = {
  value: string
  mixpanelProperty: string
  enabled: boolean
}

export type ModifiedAbTests = Record<string, AbTest>

const modifyAbTests = (abTests: AbFtPayload['abTests']['data']['abTests'], overrides?: Record<string, string>) => {
  if (!abTests) {
    return {}
  }

  return abTests.reduce((acc, { name, value, mixpanelProperty, enabled }) => {
    acc[name] = {
      value: overrides?.[name] || value,
      mixpanelProperty,
      enabled,
    }

    return acc
  }, {} as ModifiedAbTests)
}


export default modifyAbTests
