import { useCallback, useMemo, createContext, useContext } from 'react'
import { useApolloClient, useQuery } from 'apollo-client'
import type { PopupShownInput, PopupShown } from 'typings/graphql'

import shownPopupsQuery from './graph/shownPopups.graphql'
import popupShownQuery from './graph/popupShown.graphql'


export type ShownPopups = Record<string, Omit<PopupShown, 'popupId'>>

type ShownPopupsContextValue = {
  shownPopups: ShownPopups
  isFetching: boolean
}

const ShownPopupsContext = createContext<ShownPopupsContextValue>(null)

export const ShownPopupsProvider: React.CFC = ({ children }) => {
  const { data, isFetching } = useQuery(shownPopupsQuery, {
    fetchPolicy: 'cache-first',
  })

  const shownPopups: ShownPopups = useMemo<Record<string, { count: number, lastUpdated: string }>>(() => {
    const closedPopupsList = data?.currentUser?.data?.shownPopups || []

    return closedPopupsList.reduce((result, { popupId, ...rest }) => {
      result[popupId] = rest

      return result
    }, {})
  }, [ data ])

  const value: ShownPopupsContextValue = {
    shownPopups,
    isFetching,
  }

  return (
    <ShownPopupsContext.Provider value={value}>
      {children}
    </ShownPopupsContext.Provider>
  )
}

export const useShownPopups = () => useContext(ShownPopupsContext)

export const useSetPopupShown = () => {
  const client = useApolloClient()

  return useCallback((popupId: PopupShownInput['popupId']) => (
    client.mutate({
      mutation: popupShownQuery,
      variables: {
        input: {
          popupId,
        },
      },
      update: (cache, mutationResult) => {
        cache.modify({
          id: 'UserData:{}',
          fields: {
            shownPopups: (shownPopupsRef: PopupShown[]) => {
              const popupData = mutationResult.data.popupShown.data.popup

              if (popupData.count > 1) {
                return shownPopupsRef.map((shownPopup) => {
                  if (shownPopup.popupId === popupId) {
                    return popupData
                  }

                  return shownPopup
                })
              }

              return shownPopupsRef.concat(popupData)
            },
          },
        })
      },
    })
  ), [ client ])
}
