import { useCallback } from 'react'
import { useMutation } from 'apollo-client'
import { GraphQLError, UserError } from 'helpers'

import userEmailUnsubscribeQuery, { type UserEmailUnsubscribeVariables } from './graph/userEmailUnsubscribe.graphql'


export const useEmailNotificationsUnsubscribe = () => {
  const [ mutate, { isFetching } ] = useMutation(userEmailUnsubscribeQuery)

  const submit = useCallback(async (input: UserEmailUnsubscribeVariables['input']) => {
    const result = await mutate({
      variables: {
        input,
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { error } = result.data.userEmailUnsubscribe

    if (error) {
      throw new UserError(error)
    }
  }, [ mutate ])

  return [
    submit,
    {
      isFetching,
    },
  ] as const
}
