import type { AbFtPayload } from './graph/abFt.graphql'


export type ModifiedFeatures = Record<string, boolean>

const modifyFeatures = (features: AbFtPayload['features']['data']['featureToggles'], overrides?: Record<string, boolean>): ModifiedFeatures => {
  if (!features) {
    return {}
  }

  return features.reduce((acc, { name, enabled }) => {
    const queryValue = overrides?.[name]

    if (typeof queryValue !== 'undefined') {
      acc[name] = queryValue
    }
    else {
      acc[name] = enabled
    }

    return acc
  }, {})
}


export default modifyFeatures
