import { useCallback } from 'react'
import { GraphQLError, UserError } from 'helpers'
import { useMutation } from 'apollo-client'
import { useUser } from 'modules/user/user'

import { type ReferralInviteInput } from 'typings/graphql'
import referralInviteMutation from './graphql/referralInvite.graphql'


type UseReferralInvitesResult = {
  stats: UserModule.ReferralStats
}

export const useReferralInvites = (): UseReferralInvitesResult => {
  const { userInvites } = useUser()

  const stats = userInvites ? userInvites.statistics : {
    available: 0,
    sent: 0,
    redeemed: 0,
  }

  return {
    stats,
  }
}


export const useSendInvite = () => {
  const [ mutate, { isFetching } ] = useMutation(referralInviteMutation)

  const sendInvite = useCallback(async (input: ReferralInviteInput) => {
    const result = await mutate({
      variables: {
        input,
      },
      refetchQueries: [ 'User' ],
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.referralInvite

    if (error) {
      throw new UserError(error)
    }

    return data
  }, [ mutate ])

  return {
    sendInvite,
    isFetching,
  }
}
