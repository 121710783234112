import { useCallback } from 'react'
import { constants } from 'helpers'
import localStorage from 'local-storage'
import logger from 'logger'

import { type ShownPopups, useUser } from 'modules/user'


type isOptInModalShouldBeShownProps = {
  brandSlug: string
  shownPopups: ShownPopups
}

type OptInEmailProps = {
  email: string
  lastName: string
  firstName: string
  projectName: Analytics.IterableProjects
}


const optInIterableProject = async({ projectName, email, firstName, lastName }: OptInEmailProps) => {
  logger.info({ email, firstName, lastName }, `[Iterable] identify (${projectName})`)

  window.fetch('/iterable/update-user', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      method: 'identify',
      project: projectName,
      data: {
        email,
        dataFields: {
          first_name: firstName,
          last_name: lastName,
        },
      },
    }),
  }).catch((error) => {
    logger.warn(error, 'Interable update user error')
  })
}

const addProjectToPostponed = (projectName: Analytics.IterableProjects) => {
  const pendingProjects = localStorage.getItem<Analytics.IterableProjects[]>(constants.localStorageNames.iterableOptInPendingProjects) || []

  if (pendingProjects.includes(projectName)) {
    return
  }

  pendingProjects.push(projectName)

  localStorage.setItem<Analytics.IterableProjects[]>(constants.localStorageNames.iterableOptInPendingProjects, pendingProjects)
}

export const shownModalPrefix = 'opt-in-brand-'
export const optInBrandsSlugs = [ 'sanctuary', 'goodhabit', 'deck-of-scarlet', 'confessions-of-a-rebel' ]

export const useOptInEmail = () => {
  const { isLoggedIn, user } = useUser()

  const optInEmail = useCallback((projectName: Analytics.IterableProjects) => {
    if (!isLoggedIn) {
      addProjectToPostponed(projectName)
      return
    }

    void optInIterableProject({
      projectName,
      email: user.email,
      lastName: user.lastName,
      firstName: user.firstName,
    })
  }, [ isLoggedIn, user ])

  return {
    optInEmail,
  }
}

export const useOptInEmailResolvePostponedProjects = () => {
  const { user } = useUser()

  return useCallback(() => {
    const pendingProjects = localStorage.getItem<Analytics.IterableProjects[]>(constants.localStorageNames.iterableOptInPendingProjects)

    pendingProjects?.forEach((projectName) => {
      void optInIterableProject({
        projectName,
        email: user.email,
        lastName: user.lastName,
        firstName: user.firstName,
      })
    })

    localStorage.removeItem(constants.localStorageNames.iterableOptInPendingProjects)
  }, [ user ])
}

export const isOptInModalShouldBeShown = (props: isOptInModalShouldBeShownProps) => {
  const { brandSlug, shownPopups } = props

  const popupName = shownModalPrefix + brandSlug
  const isOptInPopup = optInBrandsSlugs.includes(brandSlug)
  const alreadyShown = Boolean(shownPopups[popupName]?.count)

  return isOptInPopup && !alreadyShown
}

