import { createContext, useContext, useEffect } from 'react'
import { useQuery } from 'apollo-client'
import localStorage from 'local-storage'
import cookieStorage from 'cookie-storage'
import { constants } from 'helpers'
import { useLocalePreferences } from 'modules/localePreferences'

import type { UserGender } from 'typings/graphql'

import userQuery, { type UserPayload } from './graph/user.graphql'


// make it compatible with ts
type PersonalInfo = UserPayload['currentUser']['data']['personalInfo']
type ReferralInvites = UserPayload['currentUser']['data']['referralInvites']

export type ModifiedUser = PersonalInfo & {
  addressInfo: PersonalInfo['addressInfo'] & {
    shipping: Required<PersonalInfo['addressInfo']['shipping']>
    billing: Required<PersonalInfo['addressInfo']['billing']>
  }
}

export type UserContextValue = {
  user: ModifiedUser
  userInvites: ReferralInvites
  isLoggedIn: boolean
  gender: UserGender
  country: Intl.CountryCode
  dateTime: string | undefined
  growthBookUid: string | undefined
  hasBillingAddress: boolean
  hasScentProfile: boolean
  isFetching: boolean
}

const UserContext = createContext<UserContextValue>(null)

export const UserProvider: React.CFC = ({ children }) => {
  const { data, isFetching } = useQuery(userQuery, {
    fetchPolicy: 'cache-first',
  })

  const user = data?.currentUser?.data?.personalInfo as ModifiedUser
  const userInvites = data?.currentUser?.data?.referralInvites

  const isLoggedIn = user !== null
  const gender: UserGender = user ? user.gender : 'FEMALE'
  const hasBillingAddress = Boolean(user?.addressInfo?.billing)
  const hasScentProfile = Boolean(user?.analyticsMetadata?.hasScentProfile)
  const dateTime = data?.currentUser?.data?.dateTime || undefined

  const tokenApi = user?.tokenApi

  // current shipping country used for cart and offers
  const { localePreferences } = useLocalePreferences()
  const cookieCountry = cookieStorage.getItem<string>(constants.cookieNames.country) // useful for SSR
  const localStorageAddress = __CLIENT__ ? localStorage.getItem<{ country: string }>(constants.localStorageNames.shippingFormValues) : null
  const country = (
    user?.addressInfo?.shipping?.country
    || localStorageAddress?.country
    || cookieCountry
    || localePreferences.country
  ) as Intl.CountryCode

  useEffect(() => {
    // set token for WS connection
    if (tokenApi) {
      localStorage.setSessionItem('tokenApi', tokenApi)
    }
    else {
      localStorage.removeSessionItem('tokenApi')
    }
  }, [ tokenApi ])

  const serverGrowthbookUid = data?.currentUser?.data?.growthBookUid
  const cookieGrowthbookUid = cookieStorage.getItem(constants.cookieNames.growthbookUid)

  const growthBookUid = isLoggedIn ? serverGrowthbookUid : (cookieGrowthbookUid || serverGrowthbookUid)

  useEffect(() => {
    if (cookieGrowthbookUid !== growthBookUid) {
      cookieStorage.setItem(constants.cookieNames.growthbookUid, growthBookUid)
    }
  }, [ cookieGrowthbookUid, growthBookUid ])

  const value: UserContextValue = {
    user,
    userInvites,
    dateTime,
    isLoggedIn,
    gender,
    country,
    growthBookUid,
    hasBillingAddress,
    hasScentProfile,
    isFetching,
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)

export const useIsUserUpdating = () => {
  const { isFetching } = useQuery(userQuery, {
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  })

  return isFetching
}
